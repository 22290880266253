
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IWorkingDays, IWorkingYear } from '../types';
import $app from '@/plugins/modules'

@Component
export default class WorkingYear extends Vue {
  @Prop({ default: () => { } })
  readonly item: IWorkingYear | undefined;

  storing = false;
  showMonth = false;
  days = 0;
  selectedMonth: IWorkingDays | null = null;

  get currentYear(): number {
    const dt = new Date();
    return dt.getFullYear();
  }

  get currentMonth(): number {
    const dt = new Date();
    return dt.getMonth();
  }

  openMonth(month: IWorkingDays) {
    this.selectedMonth = month;
    this.days = month.days;
    this.showMonth = true;
  }

  async setWorkDays() {
    this.storing = true;
    try {
      const payload: IWorkingDays = $app.clone(this.selectedMonth);
      payload.days = this.days;
      await $app.post('/api/clinics/workingdays', payload);
      this.selectedMonth!.days = this.days;
      this.showMonth = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.storing = false;
  }
}
